import { Button, Group, Modal, Stack, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import Request from '../../models/request'
import { useRequestsStore } from './requestsStore'

interface RejectDialogProps {
    request: Request | null
    close: () => void
}

interface RejectForm {
    reason: string
}

export default function RejectDialog(props: RejectDialogProps) {
    const [updatePending, setUpdatePending] = useState(false)
    const store = useRequestsStore()

    const rejectForm = useForm<RejectForm>({
        initialValues: {
            reason: ''
        },
        validate: {
            reason: (value) => value ? null : 'Reason is required!'
        }
    })

    function confirmReject(values: RejectForm) {
        if (!props.request) return

        void store.controls.rejectRequest(props.request?.id, values.reason)
            .catch((error) => {
                console.log(error)
            })
            .then(() => {
                setUpdatePending(false)
                rejectForm.resetDirty()
                props.close()
            })
    }

    return <Modal
        opened={props.request !== null}
        onClose={props.close}
        title="Input rejection reason"
        centered
    >
        <form onSubmit={rejectForm.onSubmit((values) => confirmReject(values))}>
            <Stack align='stretch'>
                <TextInput
                    placeholder="Ex.: Doesn't match requirements"
                    {...rejectForm.getInputProps('reason', { withError: true })}
                ></TextInput>
                <Group position='right'>
                    <Button
                        color='yamnaPink'
                        variant='subtle'
                        onClick={props.close}
                        type='reset'
                    >Cancel</Button>
                    <Button
                        color='yamnaPink'
                        loading={updatePending}
                        type='submit'
                    >Confirm</Button>
                </Group>
            </Stack>
        </form>
    </Modal>
};

import { Button, Group, Modal, Stack, Text } from '@mantine/core'
import Request from '../../models/request'
import { useMemo } from 'react'

interface SeeNoteDialogProps {
    request: Request | null
    close: () => void
}

export default function SeeNoteDialog(props: SeeNoteDialogProps) {
    const title: string | null = useMemo(() => {
        if (!props.request) return null

        switch (props.request.status) {
            case 'pending':
                return 'Note for pending state'
            case 'rejected':
                return 'Reason for rejection'
            default:
                throw new Error('Note can be displayed only for states `pending`, `rejected`. But current status is ' +
                    props.request.status)
        }
    }, [props.request])
    return <Modal
        opened={props.request !== null}
        onClose={props.close}
        centered
        title={title}
        withCloseButton={false}
    >
        <Stack align='stretch'>
            <Text fz={'sm'}>{ props.request?.note }</Text>
            <Group position='right'>
                <Button
                    color='yamnaPink'
                    variant='subtle'
                    onClick={props.close}
                >Ok</Button>
            </Group>
        </Stack>
    </Modal>
};

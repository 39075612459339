import { ActionIcon, Card, Group, LoadingOverlay, ScrollArea, Table, Text, Tooltip } from '@mantine/core'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { CheckCircle, HelpCircle, MessageSquare, Slash } from 'react-feather'
import Request from '../../models/request'
import ApproveDialog from './ApproveDialog'
import RejectDialog from './RejectDialog'
import SeeNoteDialog from './SeeNoteDialog'
import SetPendingDialog from './SetPendingDialog'
import { useRequestsStore } from './requestsStore'

export default function Requests() {
    const [currentRejectRequest, setCurrentRejectRequest] = useState<Request | null>(null)
    const [currentSetPendingRequest, setCurrentSetPendingRequest] = useState<Request | null>(null)
    const [currentSeeNoteRequest, setCurrentSeeNoteRequest] = useState<Request | null>(null)
    const [currentApproveRequest, setCurrentApproveRequest] = useState<Request | null>(null)

    const store = useRequestsStore()

    function formatDate(dateString: string) {
        return moment(dateString).calendar()
    }

    useEffect(() => store.controls.fetchRequests(), [])

    const buttonDisabledStatuses: Record<Request['status'], Array<'approve' | 'reject' | 'setPending' | 'notes'>> = {
        new: ['notes'],
        approved: ['notes', 'reject', 'approve', 'setPending'],
        pending: ['setPending'],
        rejected: ['setPending', 'approve', 'reject']
    }

    return <>
        <LoadingOverlay visible={store.loading} ></LoadingOverlay>
        <Card
            radius={'lg'}
            shadow={'md'}
            mx={'md'}
            px={'xl'}
            mah={'65vh'}
        >
            <ScrollArea h={'64vh'}>
                <Table striped highlightOnHover
                    horizontalSpacing="xl" verticalSpacing="md"
                    className="cusTomTable">
                    <thead>
                        <tr>
                            <th>Date time</th>
                            <th>Full name</th>
                            <th>Speciality</th>
                            <th>Cabinet address</th>
                            <th>Phone number</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { store.requests?.map((request) => {
                            return (
                                <tr key={request.id} >
                                    <td>{formatDate(request.datetime)}</td>
                                    <td><Text weight={600}>{request.fullName}</Text></td>
                                    <td>{request.speciality}</td>
                                    <td>{request.cabinetAddress}</td>
                                    <td>{request.phoneNumber}</td>
                                    <td>{request.email}</td>
                                    <td style={{ textTransform: 'capitalize' }}>{request.status}</td>
                                    <td>
                                        <Group>
                                            <Tooltip label="Approve" inline>
                                                <ActionIcon color="yamnaGreen" radius="xl"
                                                    variant="filled"
                                                    onClick={() => {
                                                        setCurrentApproveRequest(request)
                                                    }}
                                                    disabled={buttonDisabledStatuses[request.status].includes('approve')}
                                                >
                                                    <CheckCircle size={15} />
                                                </ActionIcon>
                                            </Tooltip>
                                            <Tooltip label="Reject" inline>
                                                <ActionIcon
                                                    color="yamnaGreen"
                                                    radius="xl"
                                                    variant="filled" onClick={() => {
                                                        setCurrentRejectRequest(request)
                                                    }}
                                                    disabled={buttonDisabledStatuses[request.status].includes('reject')}
                                                >
                                                    <Slash size={15} />
                                                </ActionIcon>
                                            </Tooltip>
                                            <Tooltip label="Set pending" inline>
                                                <ActionIcon color="yamnaGreen" radius="xl"
                                                    variant="filled" onClick={() => {
                                                        setCurrentSetPendingRequest(request)
                                                    }}
                                                    disabled={buttonDisabledStatuses[request.status].includes('setPending')}
                                                >
                                                    <HelpCircle size={15} />
                                                </ActionIcon>
                                            </Tooltip>
                                            <Tooltip label="See note" inline>
                                                <ActionIcon color="yamnaGreen" radius="xl"
                                                    variant="filled" onClick={() => setCurrentSeeNoteRequest(request)}
                                                    disabled={buttonDisabledStatuses[request.status].includes('notes')}
                                                >
                                                    <MessageSquare size={15} />
                                                </ActionIcon>
                                            </Tooltip>
                                        </Group>
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>
            </ScrollArea>
        </Card>

        <RejectDialog request={currentRejectRequest} close={() => setCurrentRejectRequest(null)}></RejectDialog>
        <SetPendingDialog request={currentSetPendingRequest} close={() => setCurrentSetPendingRequest(null)}></SetPendingDialog>
        <SeeNoteDialog request={currentSeeNoteRequest} close={() => setCurrentSeeNoteRequest(null)}></SeeNoteDialog>
        <ApproveDialog request={currentApproveRequest} close={() => setCurrentApproveRequest(null)}></ApproveDialog>
    </>
}

import { Button, Group, Modal, Stack, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import Request from '../../models/request'
import { useRequestsStore } from './requestsStore'

interface SetPendingDialogProps {
    request: Request | null
    close: () => void
}

interface SetPendingForm {
    note: string
}

export default function SetPendingDialog(props: SetPendingDialogProps) {
    const [updatePending, setUpdatePending] = useState(false)
    const store = useRequestsStore()

    const rejectForm = useForm<SetPendingForm>({
        initialValues: {
            note: ''
        },
        validate: {
            note: (value) => value ? null : 'Note is required!'
        }
    })

    function confirm(values: SetPendingForm) {
        if (!props.request) return

        void store.controls.setRequestPending(props.request?.id, values.note)
            .catch((error) => {
                console.log(error)
            })
            .then(() => {
                setUpdatePending(false)
                rejectForm.resetDirty()
                props.close()
            })
    }

    return <Modal
        opened={props.request !== null}
        onClose={props.close}
        title="Add note"
        centered
    >
        <form onSubmit={rejectForm.onSubmit((values) => confirm(values))}>
            <Stack align='stretch'>
                <TextInput placeholder="Ex.: Ask for additional info" {...rejectForm.getInputProps('note')}></TextInput>
                <Group position='right'>
                    <Button
                        color='yamnaPink'
                        variant='subtle'
                        onClick={props.close}
                        type='reset'
                    >Cancel</Button>
                    <Button
                        color='yamnaPink'
                        loading={updatePending}
                        type='submit'
                    >Confirm</Button>
                </Group>
            </Stack>
        </form>
    </Modal>
};

import { AspectRatio, Button, Card, Container, createStyles, Group, Image, Space, Stack, Text } from '@mantine/core'
import { Phone } from 'react-feather'
import Logo from '../assets/Images/logo.svg'
import OnBoardingImage from '../assets/Images/onboarding.svg'

const useStyles = createStyles((theme) => ({
    wrapper: {
        backgroundColor: '#E9EFF4',
        padding: '3rem',
        paddingBottom: 0,
        paddingTop: '2.25rem',
        maxHeight: '100%',
        boxSizing: 'border-box',
        overflowY: 'hidden'
    },

    onBoardingLogo: {
        objectPosition: 'left'
    },

    onBoardingTitle: {
        fontFamily: 'Poppins',
        fontSize: '48px'
    },

    onBoardingCaption: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#AEB7AF'
    }
}))

export default function OnBoardingLayout({ children }: any) {
    const { classes } = useStyles()

    return (
        <Container className={classes.wrapper}
            fluid>
            <Group
                align={'stretch'}
                grow
            >
                <Stack
                    align={'stretch'}
                    justify={'space-between'}
                >

                    <Image
                        src={Logo}
                        classNames={{ image: classes.onBoardingLogo }}
                        height={48}
                        fit={'contain'}>
                    </Image>

                    <Space h={'lg'}></Space>

                    <Text className={classes.onBoardingTitle}>
                        Yamna. <br />
                        Caring around, <br/>
                        for <strong>you.</strong>
                    </Text>

                    <AspectRatio
                        ratio={16 / 9}
                        style={{ maxHeight: '80%' }}>

                        <Image src={OnBoardingImage}
                            height={'50%'}
                            fit={'contain'}>
                        </Image>
                    </AspectRatio>

                    <Text
                        className={classes.onBoardingCaption}
                    >© Copyright 2022 • Doctomeet • Terms & Services • Privacy policy</Text>
                </Stack>
                <Stack align={'stretch'}
                    justify={'space-between'}>

                    <Stack align={'end'}>
                        <Button
                            variant='subtle'
                            color={'dark'}
                            leftIcon={<Phone/>}
                        >Assistance</Button>
                    </Stack>

                    <Card shadow={'md'}
                        radius={'lg'}
                        p={56}
                        style={{ height: '100%' }}>
                        {children}
                    </Card>

                    <Space></Space>
                </Stack>
            </Group>
        </Container>
    )
}

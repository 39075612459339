import {
    Button,
    createStyles,
    Group,
    LoadingOverlay,
    PasswordInput,
    Space,
    Stack,
    Text,
    TextInput, UnstyledButton
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { FaChevronRight } from 'react-icons/fa'
import { Link, Navigate } from 'react-router-dom'
import OnboardingLayout from '../layouts/OnboardingLayout'
import { useAuthStore } from '../stores/authStore'

const useStyles = createStyles((theme) => ({
    loginCreateAccountMessage: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px'
    }
}))

interface LoginRequest {
  email: string
  password: string
}

function Login() {
    const { classes } = useStyles()
    const { controls, authInProgress, loggedIn } = useAuthStore()

    const form = useForm({
        initialValues: {
            email: '',
            password: ''
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            password: (value) => (value.length < 6 ? 'Password length' : null)
        }
    })

    function submitLogin(params: LoginRequest) {
        void controls.login(params.email, params.password)
    }

    if (!authInProgress && loggedIn) { return <Navigate to={'/requests'} /> }

    return (
        <OnboardingLayout>
            <LoadingOverlay visible={authInProgress}></LoadingOverlay>
            <Space h={'lg'}></Space>
            <h2>Connectez-vous à votre compte</h2>
            <form onSubmit={form.onSubmit((data) => submitLogin(data))} style={{ height: '80%' }}>
                <Stack justify={'space-between'} style={{ height: '100%' }}>
                    <Stack align={'stretch'}>
                        <TextInput
                            size="lg"
                            radius="lg"
                            variant="filled"
                            type='email'
                            placeholder="Adresse e-mail"
                            {...form.getInputProps('email')}
                            classNames={{ input: 'customInput' }}
                        />

                        <PasswordInput
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Mot de passe"
                            {...form.getInputProps('password')}
                            classNames={{ input: 'customInput' }}
                        />

                        <UnstyledButton>
                            <Text
                                color="#1890FF"
                                size={'md'}
                                mx="6px"
                            >
                                Mot de passe oublié ?
                            </Text>
                        </UnstyledButton>

                        <Button
                            type="submit"
                            fullWidth
                            size="lg"
                            radius="md"
                            rightIcon={<FaChevronRight />}
                            styles={() => ({
                                inner: {
                                    justifyContent: 'space-between'
                                }
                            })}
                        >
                            Connexion
                        </Button>
                    </Stack>

                    <Stack align={'start'}>
                        <Text weight={600}>
                            New to Yamna ?
                        </Text>
                        <Text className={classes.loginCreateAccountMessage}>
                              Create your account in few steps
                        </Text>

                        <UnstyledButton
                            component={Link}
                            to="/signup">
                            <Group>
                                <Text color="#1890FF" size={'md'}>
                                    Create your account
                                </Text>
                            </Group>
                        </UnstyledButton>
                    </Stack>
                </Stack>
            </form>
        </OnboardingLayout>
    )
}

export default Login

import { Button, createStyles, Group, Header, Image, Menu } from '@mantine/core'
import { useEffect } from 'react'
import {
    Edit2,
    Inbox,
    LogOut,
    Phone,
    Search,
    Settings,
    User
} from 'react-feather'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAuthStore } from '../../stores/authStore'
import ButtonHeader from './buttons/HeaderButton'

const useStyle = createStyles(() => ({
    logo: {
        marginTop: '30px',
        marginLeft: '50px'
    },
    navBar: {
        marginTop: '130px',
        marginLeft: '50px'
    }
}))

export default function AppHeader() {
    const { controls } = useAuthStore()

    const navigate = useNavigate()
    const currentRoute = useLocation()
    const { classes } = useStyle()

    useEffect(() => {
        void controls.loadCurrentUser()
    }, [])

    function logout() {
        void controls.logout()
        navigate('/login')
    }

    return (
        <Header
            height={'auto'}
            style={{
                backgroundColor: '#033446',
                minHeight: '264px'
            }}
        >
            <Group position="apart">
                <NavLink
                    className={classes.logo}
                    to="/"
                >
                    <Image
                        src={require('../../assets/Images/logo.png')}
                        alt={'logo'}
                        style={{ width: 147, height: 40 }}
                    />
                </NavLink>
                <Group >
                    <ButtonHeader
                        color="#01212c"
                        to="/dashboard"
                        icon={<Search size={20} />}
                        content='Rechercher'/>
                    <ButtonHeader
                        color="#01212c"
                        to="/dashboard"
                        icon={<Phone size={20} />}
                        content='Assistance'/>
                    <ButtonHeader
                        color="#01212c"
                        to="/dashboard"
                        icon={<Settings size={20} />}
                        content='Paramètres' />

                    <Menu>
                        <Menu.Target>
                            <Button
                                leftIcon={<User size={20} />}
                                variant="subtle"
                                color="white"
                                size="md"
                                styles={(theme) => ({
                                    root: {
                                        height: 48,
                                        borderRadius: 9,
                                        '&:hover': {
                                            backgroundColor: '#01212c'
                                        },
                                        '&:active': {
                                            backgroundColor: ' #01212c'
                                        }
                                    }
                                })}>
                                    Profil
                            </Button>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Item icon={<Edit2 size={14} />} onClick={() => navigate('/profile')}>Edit profile</Menu.Item>
                            <Menu.Item icon={<LogOut size={14} />} onClick={() => logout()}>Logout</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>

            <Group className={classes.navBar}>
                <ButtonHeader
                    color="#FF526F"
                    to="/requests"
                    active={currentRoute.pathname === '/requests'}
                    icon={<Inbox size={20} />}
                    content='Requests' />
            </Group>
        </Header>
    )
};

import create from 'zustand'
import apiClient from '../../api/ApiClient'
import Request from '../../models/request'
import User from '../../models/user'
import moment from 'moment'
interface RequestsStore {
  requests: Request[] | null
  loading: boolean
  controls: {
    fetchRequests: () => void
    rejectRequest: (requestId: number, reason: string) => Promise<void>
    approveRequest: (requestId: number, tariff: User['tariff'], activationExpireDate?: Date) => Promise<void>
    setRequestPending: (requestId: number, reason: string) => Promise<void>
  }
}

const useRequestsStore = create<RequestsStore>((set) => ({
    requests: null,
    loading: false,
    controls: {
        fetchRequests: () => {
            set({ loading: true })

            apiClient.get('request').then(response => {
                const requests = response.data

                set({ requests })
            }).finally(() => set({ loading: false }))
        },
        async rejectRequest(id, reason) {
            set({ loading: true })

            apiClient.post(`request/${id}/reject`, { reason }).then(async () => {
                return await apiClient.get('request').then(response => {
                    const requests = response.data

                    set({ requests })
                })
            }).finally(() => set({ loading: false }))
        },
        async approveRequest(requestId, tariff, activationExpireDate) {
            set({ loading: true })

            apiClient.post(`request/${requestId}/approve`,
                { plan: tariff, activatedUntil: moment(activationExpireDate).format() }
            ).then(async () => {
                return await apiClient.get('request').then(response => {
                    const requests = response.data

                    set({ requests })
                })
            }).finally(() => set({ loading: false }))
        },
        async setRequestPending(id, note) {
            set({ loading: true })

            apiClient.post(`request/${id}/pending`, { note }).then(async () => {
                return await apiClient.get('request').then(response => {
                    const requests = response.data

                    set({ requests })
                })
            }).finally(() => set({ loading: false }))
        }
    }
}))

export { useRequestsStore }

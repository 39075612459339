import { Button, Group, LoadingOverlay, Modal, SegmentedControl, Stack } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import moment from 'moment'
import { useState } from 'react'
import Request from '../../models/request'
import { useRequestsStore } from './requestsStore'

interface ApproveDialogProps {
  close: () => void
  request: Request | null
}

interface ApproveForm {
  tariff: 'BASIC' | 'PRO'
  activatedUntil?: Date
}

function ApproveDialog(props: ApproveDialogProps) {
    const [approvePending, setUpdatePending] = useState(false)
    const store = useRequestsStore()

    const approveForm = useForm<ApproveForm>({
        initialValues: {
            tariff: 'PRO',
            activatedUntil: moment().add(1, 'month').toDate()
        }
    })

    function confirm(values: ApproveForm) {
        if (!props.request) return

        setUpdatePending(true)

        store.controls.approveRequest(
            props.request.id,
            values.tariff,
            values.activatedUntil
        ).then(() => props.close()).finally(() => setUpdatePending(false))
    }

    return (
        <Modal
            opened={props.request !== null}
            onClose={props.close}
            title="Select plan"
            centered
        >
            <form
                onSubmit={approveForm.onSubmit(confirm)}
            >
                <LoadingOverlay
                    visible={approvePending}
                    radius={30}
                    loaderProps={{ color: 'yamnaPink' }}
                />
                <Stack>
                    <SegmentedControl data={[
                        { label: 'Basic', value: 'BASIC' },
                        { label: 'Pro', value: 'PRO' }
                    ]}
                    fullWidth
                    {...approveForm.getInputProps('tariff')}
                    ></SegmentedControl>
                    <DateInput
                        label="Tariff expire date"
                        {...approveForm.getInputProps('activatedUntil')}
                        disabled={approveForm.values.tariff !== 'PRO'}
                        minDate={new Date()}
                        defaultLevel='year'
                    />
                    <Group position='right'>
                        <Button
                            color='yamnaPink'
                            variant='subtle'
                            onClick={props.close}
                            type='reset'
                        >Cancel</Button>
                        <Button
                            color='yamnaPink'
                            loading={approvePending}
                            type='submit'
                        >Confirm</Button>
                    </Group>
                </Stack>
            </form>
        </Modal>
    )
}

export default ApproveDialog
